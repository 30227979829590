import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Recall } from '../interfaces/recall';
import {Vehiculo } from '../interfaces/vehiculo';

@Injectable({
  providedIn: 'root'
})
export class RecallService {

  recallResponse: Recall[];

  constructor(
    private httpClient: HttpClient,
  ) {}


  public getRecall(
    patente: string,

  ): Observable<any> {

    let tipo = patente.length > 8 ? 'vin' : 'patente';
    let endpoint = environment.consultaRecallEndpoint + "?" + tipo +"=" + patente;

    return this.httpClient
      .get(endpoint, { headers: { 'Ocp-Apim-Subscription-Key': environment.apiKey } })
      .pipe(
        map((data: any) => {
          this.recallResponse = data;
          return this.recallResponse;
        })
      );
  }


  public getVehiculo(
    patente: string,

  ): Observable<Vehiculo> {

    let endpoint = environment.consultaVehiculoEndpoint +  "?$filter=NumeroCuenta eq '' and StringSociedades eq '' and Placa eq '" + patente + "'&$format=json";

    return this.httpClient
      .get(endpoint, { headers: { 'Ocp-Apim-Subscription-Key': environment.apiKey } })
      .pipe(
        map((data: any) => {
          return data.d.results[0];
        })
      );
  }



  public validateRecaptcha(response){
    let endpoint = "https://qa-recall.divemotor.com/api-recapcha-validate";

    return this.httpClient
      .post(endpoint, { "g-recaptcha-response" : response  })
      .pipe(
        map((data: any) => {
          return data;
        })
      );

  }


 


}
