<h2 mat-dialog-title cdkFocusInitial>{{ faqs.title }}</h2>
<hr>
<mat-dialog-content class="mat-typography"> 

  <p>{{ faqs.description }}</p>
  <mat-accordion class="example-headers-align">
    <mat-expansion-panel *ngFor="let q of faqs.questions;let i = index" [expanded]="i == 0">
      <mat-expansion-panel-header>
        <mat-panel-title class="title-question" [innerHTML]="q.question"> </mat-panel-title>
      </mat-expansion-panel-header>
  
      <p [innerHTML]="q.answer"></p>
  
    </mat-expansion-panel>

    <br>
  
  </mat-accordion>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-stroked-button mat-dialog-close>Cerrar</button>
</mat-dialog-actions>

