import { Component } from '@angular/core';
import { NavigationEnd, ResolveEnd, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  
  iconUrl = '';
  currentStyle = "divemotor-style";
  favIcon: HTMLLinkElement = document.querySelector('#appIcon'); 
  appTitle: HTMLTitleElement = document.querySelector('#appTitle'); 

  constructor(
    private _router: Router,
  ) {
    if(environment.production){
      this.setUpAnalytics();
    }

    //Change Icon Based on URL Divemotor/Andesmotor
    this.iconUrl = window.location.href.includes('andesmotor') ? 'assets/icons/andesmotor-style.ico':'assets/icons/divemotor-style.ico';
    this.favIcon.href = this.iconUrl;

    this.currentStyle = window.location.href.includes('andesmotor') ? 'andesmotor-style':'divemotor-style';
    
  }

  ngOnInit() {
    this._router.events.subscribe((routerData) => {
      if(routerData instanceof ResolveEnd){ 
        console.log(routerData.url);
      }
    })

  }


  //Setup GA4
  setUpAnalytics() {
    this._router.events.subscribe(event => {
      if(event instanceof NavigationEnd){
        gtag('config', 'G-4FDHET1HFV',{
          'page_path': event.urlAfterRedirects,
          'page_title': event.urlAfterRedirects
        });
       }
    })
  }

}
