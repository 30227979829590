import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'prioridad'
})
export class PrioridadPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    let splitted = value.toString().split('-');

    return splitted[1] ? splitted[1].trim()  : 'No asignado' ;
  }

}
