import { Component, OnInit, ViewChild } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { faqs } from 'src/app/shared/data/faqs';

@Component({
  selector: 'app-popup-faqs',
  templateUrl: './popup-faqs.component.html',
  styleUrls: ['./popup-faqs.component.scss']
})
export class PopupFaqsComponent implements OnInit {

  @ViewChild(MatAccordion) accordion: MatAccordion;


  faqs: any  = faqs;

  constructor() { }

  ngOnInit(): void {
  }

}
