import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { RecaptchaLoaderService, ReCaptchaV3Service } from 'ng-recaptcha';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Recall } from 'src/app/shared/interfaces/recall';
import { Vehiculo } from 'src/app/shared/interfaces/vehiculo';
import { RecallService } from 'src/app/shared/services/recall.service';
import { PopupFaqsComponent } from '../popup-faqs/popup-faqs.component';
import { PopupImgreferentialComponent } from '../popup-imgreferential/popup-imgreferential.component';

declare let gtag: Function; 

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {


  validRecaptcha = false;

  panelOpenState = true;
  searchFormGroup: FormGroup;
  recallArray: Array<Recall> = [];
  datosVehiculo: Vehiculo; 

  company = window.location.href.includes('andesmotor') ? 'ANDESMOTOR':'DIVEMOTOR';
  agendamientoLink = window.location.href.includes('andesmotor') ? 'https://reservas.andesmotor.pe':'https://reservas.divemotor.com';
  
  

  constructor(
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private _formBuilder: FormBuilder,
    public recallService: RecallService,
    private _snackBar: MatSnackBar,
    public loader: NgxUiLoaderService, 
    ) {     
    this.searchFormGroup = this._formBuilder.group({
      placa: ['', Validators.compose([Validators.maxLength(17)])],
    });  

   }

  ngOnInit(): void {
    if(this.route.snapshot.params['codigo'] !== undefined){
      this.searchFormGroup.get('codigoReserva').setValue(this.route.snapshot.params['codigo']);
      this.searchBook();
    }
  }

  resolved(captchaResponse: string) {
    this.recallService.validateRecaptcha(captchaResponse).subscribe((data) => {
       if(data.success) {
        this.validRecaptcha = true;
       }
       else{
        this.validRecaptcha = false;
       }
    });

  }

  searchBook() {
    const placa = this.searchFormGroup.value.placa;

    if(!this.validRecaptcha) {
      this._snackBar.open(
        'Debe completar el captcha',
        'X',
        {
          duration: 4000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        }
      );
      return;
    }

    if (placa.length === 0) {
      this._snackBar.open(
        'Ingrese una Placa o VIN',
        'X',
        {
          duration: 4000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        }
      );

      return;
    } 

    //Clear current search
    this.recallArray = [];

    this.loader.start('search-recall');
    this.recallService
      .getRecall(placa)
      .subscribe({
        next : (data) => {
          if(data.estado == 0){
            this.recallArray = data.recall;
            if(data.recall === undefined){
              this.recallArray = [];
            }
          }
          else{
            this.recallArray = [];
          }

          this.loader.stop('search-recall');
          this.validateBook();
        },
        error : (e) => {
          this.loader.stop('search-recall');

          this._snackBar.open(
            'El servio de consulta no se encuentra disponible. Intente más tarde.',
            'X',
            {
              duration: 4000,
              horizontalPosition: 'center',
              verticalPosition: 'top',
            }
          );

        }
    });


      this.recallService
      .getVehiculo(placa)
      .subscribe((data) => {
        this.datosVehiculo = data;
        console.log(this.datosVehiculo);
      });
  }

  validateBook(){
    if (this.recallArray.length === 0) {
      this._snackBar.open(
        'No se encontraron registros con los datos ingresados.',
        'X',
        {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        }
      );
    } else {
      console.log('Aqui', this.recallArray);
    }
  }


  clearRecall(){
    this.datosVehiculo = undefined;
    this.recallArray = [];
  }



  preguntasFrecuentes(){
    const dialogRef = this.dialog.open(PopupFaqsComponent, {
      //height: '600px',
      width: '800px',
      panelClass: 'my-dialog',
    });
  }

  ImgReferencial(){
    const dialogRef = this.dialog.open(PopupImgreferentialComponent, {
      //height: '600px',
      width: '580px',
      panelClass: 'my-dialog',
    });
  }


}
