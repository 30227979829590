<div class="material-content" >
    <mat-card>


      <div class="parent" *ngIf="recallArray.length == 0">
        <div class="detail">
          <h2 id="datail-title">Llamados a revisión <span>-</span>Recalls</h2>
          <p>Los Recalls son llamados a revisión que las marcas automotrices realizan a nivel mundial como parte del programa de mejora continua. </p>           
          <p>Para el mercado peruano, <b>{{ company }}</b> brinda estas atenciones dentro de su política de servicio al cliente con el objetivo de mantener un producto de alta calidad que asegure su completa satisfacción.</p>
        <button class="button-faqs" mat-button mat-stroked-button (click)="preguntasFrecuentes()"  color="primary">Preguntas frecuentes</button>
        </div>
        <div class="options">

          <div class="recall-info">
            <div>
              <img src="/assets/images/icon-mega-x3.png" style="width: 55px; height: 44px;">
            </div>
            <div>
              <p>El llamado a revisión es de carácter gratuito y se puede efectuar en cualquier punto de servicio de la red de <b>{{ company }}</b>.</p>
            </div>
          </div>


          <h2>Consultar Recall</h2>

          <form [formGroup]="searchFormGroup" class="search-form">
            <div class="box-form" style="display: flex; justify-content: space-between;">
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>Placa/VIN</mat-label>
              <input matInput 
              maxlength="17"
              placeholder="Ej: ABC-123 ó 1A0H1111111111111" 
              formControlName="placa"
              >
            </mat-form-field>   
            <re-captcha (resolved)="resolved($event)" siteKey="6LfphFMjAAAAAGH3FI9uRlcZmKCd7U86TdK1ITIp"></re-captcha>
          </div>            
          </form>
          <div class="text-aid">
            <p><b>¿No sabes dónde está el VIN?</b></p>             
            <p id="pf-aid">Nosotros te ayudamos</p>
            <span class="text-img-referential"(click)="ImgReferencial()">mira nuestra imagen referencial aquí.</span>
          </div>
        </div>
      </div>
    
      <div class="parent">

        


        <div class="detail box-resume" *ngIf="recallArray.length > 0">
            <div class="resume" >
              <h2>Detalle de su vehículo</h2>
              <p matLine *ngIf="datosVehiculo">
                <b>Marca:</b> <span> {{ datosVehiculo.Marca }}</span>
              </p>

              <p matLine *ngIf="datosVehiculo">
                <b>Modelo:</b> <span> {{ datosVehiculo.ModeloGenerico || datosVehiculo.ModeloVehiculo }}</span>
              </p>

              <p matLine>
                <b>Placa:</b> <span> {{ recallArray[0].patente }}</span>
              </p>
              
              <p matLine>
                <b>Vin:</b><span> {{  recallArray[0].vin }}</span>
              </p>         
          </div>
          
        </div>

        <div class="options">

          <mat-accordion>
           
            
            <mat-expansion-panel class="style-table" *ngFor="let recall of recallArray">
              <mat-expansion-panel-header class="table-flex-column">
                <table>
                  <thead>
                    <tr>
                      <th>Recall</th>
                      <th class="td-size hide-on-mobile">Descripción</th>
                      <th class="t-size-standard  hide-on-mobile">Prioridad</th>                     
                      <th class="t-size-standard  hide-on-mobile">Estado</th>
                    </tr>               
                  </thead>                                 
                  <tbody>                   
                    <tr>
                      <td><b>{{ recall.nroReclamoGarantia.replace("RECALL","").replace("Recall","").replace("recall", "") }}</b>
                        <span class="show-on-mobile"><br>{{ recall.textoReclamoGarantia }}</span></td>
                      <td class="td-size hide-on-mobile">{{ recall.textoReclamoGarantia }}</td>
                      <td class="t-size-standard  hide-on-mobile">{{ recall.prioridad | prioridad }}</td>                      
                      <td class="t-size-standard  hide-on-mobile" [ngClass]="{'incomplete' : recall.estado == 'Incompleto', 'complete' : recall.estado == 'Completo'  }" >{{ recall.estado }}</td>
                    </tr>
                  </tbody>
                </table>
              </mat-expansion-panel-header>
              <div class="body-table-recall">
                <div class="show-on-mobile">
                  <h2><b>Prioridad</b></h2><p>{{ recall.prioridad | prioridad }}</p>                 
                  <h2><b>Estado</b></h2><p [ngClass]="{'incomplete' : recall.estado == 'Incompleto', 'complete' : recall.estado == 'Completo'  }" >{{ recall.estado }}</p>
                  <hr>                      
                </div>
                <h2 *ngIf="recall.tipoAccion"><b>Tipo de acción</b></h2><p>{{ recall.tipoAccion}}</p>
                <h2 *ngIf="recall.riesgo"><b>Riesgo</b></h2> <p>{{ recall.riesgo}}</p>
                <h2 *ngIf="recall.accionCorrectiva"><b>Acción correctiva</b></h2><p>{{ recall.accionCorrectiva}}</p>
                <a *ngIf="recall.estado != 'Completo' " class="recall-button" [href]="agendamientoLink" target="_blank" mat-button mat-raised-button >Agendar Recall</a>
              </div>
            </mat-expansion-panel>
          </mat-accordion>


        </div>
      </div>

      <div class="form-buttons-container">
        <button *ngIf="recallArray.length == 0" class="search-button" mat-button mat-raised-button  color="primary" (click)="searchBook()" [disabled]="!validRecaptcha" [title]="!validRecaptcha ? 'Debe completar el Recaptcha' : ''">Buscar</button>
        <button *ngIf="recallArray.length > 0" mat-button mat-raised-button (click)="clearRecall()" style="background-color:#00000000;color: #333333; width: 100px; height: 36px; border-radius: 8px !important; border: 1px solid #333333; box-shadow: none !important;">Atrás</button>
      </div>

    </mat-card>
</div>
  
  