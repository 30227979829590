<mat-toolbar class="header" [ngClass]="currentStyle">

  <!--Divemotor-->
  <a href="https://www.divemotor.com" target="_blank" *ngIf="currentStyle==='divemotor-style'" ><img src="/assets/images/logo-divemotor.svg" width="175"></a>

  <!--Andesmotor-->
  <a href="https://www.andesmotor.pe" target="_blank" *ngIf="currentStyle==='andesmotor-style'" ><img src="/assets/images/logo-andesmotor.svg" width="175"></a>

  <span class="example-spacer"></span>
</mat-toolbar>

<div class="app-content" [ngClass]="currentStyle">
  <router-outlet></router-outlet>
  <ngx-ui-loader></ngx-ui-loader>
</div>

<mat-toolbar class="footer" *ngIf="currentStyle==='divemotor-style'">
  <div class="footer-brand">
    <div>
      <img src="/assets/images/logo-divemotor-footer.png" alt="Logo Divemotor">
    </div>
    <div class="rrss" style="width:200px;display:flex">
        <a href="https://www.facebook.com/divemotor" target="_blank"><img src="/assets/images/icon-facebook-x3.png" style="width: 32px; height: 32px;"></a>
        <a href="https://www.youtube.com/user/divemotorperu" target="_blank"><img src="/assets/images/icon-youtube-x3.png" style="width:30px; height:30px"></a>
        <a href="https://www.linkedin.com/company/divemotor/" target="_blank"><img src="/assets/images/icon-linkedin-x3.png" style="width:27px; height:25px"></a>
    </div>
  </div>
  <div class="footer-links">
    <div><p>© 2023 DIVEMOTOR - Todos los derechos reservados.</p></div>
    <div><a target="_blank" href="https://www.divemotor.com">www.divemotor.com</a></div>
    <div><a target="_blank" href="https://wa.me/5117122000?text=Hola%20necesito%20información">Central telefónica y WhatsApp: 7122000</a></div>
  </div>
</mat-toolbar>


<mat-toolbar class="footer-andesmotor" *ngIf="currentStyle==='andesmotor-style'">
  <div class="footer-brand">
    <div>
     <a href="http://www.andesmotor.pe" target="_blank"> <img src="/assets/images/logo-andesmotor-footer.png" alt="Logo Andesmotor"></a>
    </div>
    <div class="rrss">
        <a href="http://www.andesmotor.pe" target="_blank">© 2023 Andes Motor Perú Derechos reservados.<br> Av. Elmer Faucett N° 217. Callao.</a>      
    </div>
  </div>
</mat-toolbar>

<a target="_blank" href="https://wa.me/5117122000?text=Hola%20necesito%20información" class="ws-button">
  <img src="assets/images/ws-sidebar.png" width="50" title="Contactar por WhatsApp">
</a>