const company = window.location.href.includes('andesmotor') ? 'ANDESMOTOR':'DIVEMOTOR';
const brands = window.location.href.includes('andesmotor') ? 'Maxus / Iveco / Sany':'Mercedes-Benz / STELLANTIS';

export const faqs = {
    title: "Preguntas frecuentes",
    description: "Consulte nuestra lista de preguntas frecuentes a continuación para obtener más información sobre los recalls y mantener su vehiculo en buen estado. ",
    questions:[
        {
            question: "¿Qué es un llamado a revisión de seguridad?",
            answer: "La Acción de Fábrica o también conocida como recall, es la comunicación de “llamado a revisión“ que el fabricante de un producto, cualquiera sea la industria, hace llegar a su cliente, respecto de una mejora en el mismo o de una posibilidad de que un número, siempre determinado del producto comercializado, eventualmente pudiera presentar un determinado desperfecto. <br><br> La difusión de una Acción de Fábrica no debe entenderse por sí misma como la existencia cierta de un desperfecto en el producto comprendido en tal “llamado a revisión”, sino que es una buena práctica mediante la cual el fabricante demuestra su interés y cuidado por el cliente. La medida se ejecuta sin cargo, incluso si el vehículo no tiene garantía y aunque no seas el propietario original. ",
        },
        {
            question: "¿Por qué las fábricas ("+ brands +") emiten llamados a revisión para sus vehículos?",
            answer: "Bajo el compromiso de brindar una sensación de seguridad y fiabilidad, las fábricas de las marcas que distribuimos, realizan periódicamente estrictos controles de calidad que no solo se limitan al proceso de producción de los vehículos sino también al funcionamiento cotidiano. <br><br> Las fábricas consideran que la seguridad es primordial y NO compromete la seguridad de los clientes y sus familias, lo más importante para nosotros y las fábricas de las marcas que distribuimos es la confianza que depositas en nuestros productos y servicios.",
        },
        {
            question: "¿Cómo debo proceder si tengo un llamado a revisión?",
            answer: "Encuentra un concesionario autorizado por "+ company +" y contáctalo para programar una cita, previamente es necesario confirmar la disponibilidad/stock de la(s) pieza(s) o herramienta(s) necesaria(s) para la atención."
        },
        {
            question: "¿Qué hago si mi vehículo tiene un llamado a revisión?",
            answer: "Si tu vehículo tiene un llamado a revisión, los concesionarios autorizados por "+ company +" lo atenderán sin cargo alguno. Contacta a un concesionario autorizado para programar la atención relacionada a un llamado a revisión."
        },
        {
            question: "¿Cuánto costará la ejecución del llamado a revisión de mi vehículo?",
            answer: "La atención se realizará sin costo para el propietario del vehículo. Solo tienes que contactar al concesionario autorizado por "+ company +" para programar un servicio."
        },
        {
            question: "¿Qué debo hacer si recibo una carta sobre un llamado a revisión y pienso que mi vehículo se encuentra comprendido?",
            answer: "La carta del llamado a revisión brindará información sobre los pasos a seguir. Si crees que has recibido el aviso por error, puedes consultar en línea con el número de VIN/serie si tu vehículo se encuentra comprendido."
        },
        {
            question: "He recibido una carta sobre un llamado a revisión del vehículo, pero el repuesto (de ser el caso) aún no está disponible. ¿Qué debo hacer?",
            answer: "Tu carta sobre el llamado a reparación brindará información sobre los pasos a seguir. Puedes contactar a tu concesionario para programar la ejecución de la medida en cuanto hayas recibido la confirmación respecto a la disponibilidad de las piezas necesarias. No todos los llamados a revisión requieren un reemplazo de piezas, pueden consistir únicamente en comprobaciones, programaciones o ajustes preventivos."
        },        
    ]
}